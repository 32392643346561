exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-page-blog-1-js": () => import("./../../../src/pages/blog-page/blog-1.js" /* webpackChunkName: "component---src-pages-blog-page-blog-1-js" */),
  "component---src-pages-blog-page-blog-2-js": () => import("./../../../src/pages/blog-page/blog-2.js" /* webpackChunkName: "component---src-pages-blog-page-blog-2-js" */),
  "component---src-pages-blog-page-blog-3-js": () => import("./../../../src/pages/blog-page/blog-3.js" /* webpackChunkName: "component---src-pages-blog-page-blog-3-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-study-page-case-study-1-js": () => import("./../../../src/pages/case-study-page/case-study-1.js" /* webpackChunkName: "component---src-pages-case-study-page-case-study-1-js" */),
  "component---src-pages-case-study-page-case-study-2-js": () => import("./../../../src/pages/case-study-page/case-study-2.js" /* webpackChunkName: "component---src-pages-case-study-page-case-study-2-js" */),
  "component---src-pages-case-study-page-case-study-3-js": () => import("./../../../src/pages/case-study-page/case-study-3.js" /* webpackChunkName: "component---src-pages-case-study-page-case-study-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-page-project-1-js": () => import("./../../../src/pages/project-page/project-1.js" /* webpackChunkName: "component---src-pages-project-page-project-1-js" */),
  "component---src-pages-project-page-project-2-js": () => import("./../../../src/pages/project-page/project-2.js" /* webpackChunkName: "component---src-pages-project-page-project-2-js" */),
  "component---src-pages-project-page-project-3-js": () => import("./../../../src/pages/project-page/project-3.js" /* webpackChunkName: "component---src-pages-project-page-project-3-js" */),
  "component---src-pages-project-page-project-4-js": () => import("./../../../src/pages/project-page/project-4.js" /* webpackChunkName: "component---src-pages-project-page-project-4-js" */),
  "component---src-pages-project-page-project-5-js": () => import("./../../../src/pages/project-page/project-5.js" /* webpackChunkName: "component---src-pages-project-page-project-5-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-read-more-blog-1-js": () => import("./../../../src/pages/read-more/blog-1.js" /* webpackChunkName: "component---src-pages-read-more-blog-1-js" */),
  "component---src-pages-read-more-builing-problem-solving-skills-js": () => import("./../../../src/pages/read-more/builing-problem-solving-skills.js" /* webpackChunkName: "component---src-pages-read-more-builing-problem-solving-skills-js" */),
  "component---src-pages-read-more-college-life-begins-js": () => import("./../../../src/pages/read-more/college-life-begins.js" /* webpackChunkName: "component---src-pages-read-more-college-life-begins-js" */),
  "component---src-pages-read-more-early-years-js": () => import("./../../../src/pages/read-more/early-years.js" /* webpackChunkName: "component---src-pages-read-more-early-years-js" */),
  "component---src-pages-read-more-evolving-role-and-leadership-development-js": () => import("./../../../src/pages/read-more/evolving-role-and-leadership-development.js" /* webpackChunkName: "component---src-pages-read-more-evolving-role-and-leadership-development-js" */),
  "component---src-pages-read-more-freelancing-and-learning-continues-js": () => import("./../../../src/pages/read-more/freelancing-and-learning-continues.js" /* webpackChunkName: "component---src-pages-read-more-freelancing-and-learning-continues-js" */),
  "component---src-pages-read-more-goals-js": () => import("./../../../src/pages/read-more/goals.js" /* webpackChunkName: "component---src-pages-read-more-goals-js" */),
  "component---src-pages-read-more-initial-days-at-riverhouse-js": () => import("./../../../src/pages/read-more/initial-days-at-riverhouse.js" /* webpackChunkName: "component---src-pages-read-more-initial-days-at-riverhouse-js" */),
  "component---src-pages-read-more-internship-at-inthemoney-js": () => import("./../../../src/pages/read-more/internship-at-inthemoney.js" /* webpackChunkName: "component---src-pages-read-more-internship-at-inthemoney-js" */),
  "component---src-pages-read-more-joining-riverhouse-js": () => import("./../../../src/pages/read-more/joining-riverhouse.js" /* webpackChunkName: "component---src-pages-read-more-joining-riverhouse-js" */),
  "component---src-pages-read-more-learning-the-value-of-culture-js": () => import("./../../../src/pages/read-more/learning-the-value-of-culture.js" /* webpackChunkName: "component---src-pages-read-more-learning-the-value-of-culture-js" */),
  "component---src-pages-read-more-more-products-at-riverhouse-js": () => import("./../../../src/pages/read-more/more-products-at-riverhouse.js" /* webpackChunkName: "component---src-pages-read-more-more-products-at-riverhouse-js" */),
  "component---src-pages-read-more-pursuining-engineering-dreams-js": () => import("./../../../src/pages/read-more/pursuining-engineering-dreams.js" /* webpackChunkName: "component---src-pages-read-more-pursuining-engineering-dreams-js" */),
  "component---src-pages-read-more-school-leadership-js": () => import("./../../../src/pages/read-more/school-leadership.js" /* webpackChunkName: "component---src-pages-read-more-school-leadership-js" */),
  "component---src-pages-read-more-smart-india-hackathon-1-js": () => import("./../../../src/pages/read-more/smart-india-hackathon-1.js" /* webpackChunkName: "component---src-pages-read-more-smart-india-hackathon-1-js" */),
  "component---src-pages-read-more-smart-india-hackathon-2-js": () => import("./../../../src/pages/read-more/smart-india-hackathon-2.js" /* webpackChunkName: "component---src-pages-read-more-smart-india-hackathon-2-js" */),
  "component---src-pages-read-more-transitioning-to-role-as-developer-js": () => import("./../../../src/pages/read-more/transitioning-to-role-as-developer.js" /* webpackChunkName: "component---src-pages-read-more-transitioning-to-role-as-developer-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

